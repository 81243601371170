import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto"; // Import Chart.js

import SHAPE_CHAR_1 from "../images/shape1.png";
import SHAPE_CHAR_2 from "../images/shape2.png";

function ChartComponent() {
  const chart1Ref = useRef<any>(null);
  const chart2Ref = useRef<any>(null);

  useEffect(() => {
    // Chart 1: Polar Area Chart
    const polarAreaConfig: any = {
      type: "polarArea",
      data: {
        labels: ["Contingency", "France", "Spain", "USA", "Argentina"],
        datasets: [
          {
            label: "Dataset 1",
            data: [40, 49, 44, 24, 15],
            backgroundColor: [
              "#AA3BC5",
              "#09B1F2",
              "#1FD5A4",
              "#FEB81A",
              "#F97431",
            ],
          },
        ],
      },
      options: {
        plugins: {
          title: {
            display: true,
            text: "World Wide Wine Production 2018",
          },
        },
      },
    };

    const ctx1 = chart1Ref.current.getContext("2d");
    if (ctx1) {
      if (chart1Ref.current.chart) {
        chart1Ref.current.chart.destroy();
      }
      chart1Ref.current.chart = new Chart(ctx1, polarAreaConfig);
    }

    // Chart 2: Doughnut Chart
    const doughnutConfig: any = {
      type: "doughnut",
      data: {
        labels: ["Italy", "France", "Spain", "USA", "Argentina"],
        datasets: [
          {
            data: [55, 49, 44, 24, 15],
            backgroundColor: [
              "#4DB866",
              "#EDB019",
              "#E36D23",
              "#F08A1E",
              "#39A2D1",
            ],
          },
        ],
      },
      options: {
        plugins: {
          title: {
            display: true,
            text: "World Wide Wine Production 2018",
          },
        },
      },
    };

    const ctx2 = chart2Ref.current.getContext("2d");
    if (ctx2) {
      if (chart2Ref.current.chart) {
        chart2Ref.current.chart.destroy();
      }
      chart2Ref.current.chart = new Chart(ctx2, doughnutConfig);
    }
  }, []); // Empty dependency array ensures this effect runs only once

  return (
    <div className="graph-section pb-100">
      <div className="container">
        <div className="row upper14">{/* Your existing JSX for layout */}</div>
        <div className="row upper15 pt-20">
          <div className="col-lg-6 col-md-12">
            <div className="single-chart">
              <canvas
                ref={chart1Ref}
                id="myChart"
                width="500"
                height="500"
              ></canvas>
              {/* Your chart menu */}
              {/* <div className="shape12">
                <img src={SHAPE_CHAR_1} alt="SHAPE_CHAR_1" />
              </div> */}
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="single-chart-two">
              <canvas
                ref={chart2Ref}
                id="yourChart"
                width="350"
                height="350"
              ></canvas>
              {/* Your chart menu */}
              {/* <div className="shape13">
                <img src={SHAPE_CHAR_2} alt="SHAPE_CHAR_2" />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChartComponent;
