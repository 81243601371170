import React, { useEffect } from "react";
import ABOUT_ICO from "../images/new/title-img.png";
import $ from "jquery";
import "wowjs/css/libs/animate.css";
import { WOW } from "wowjs";

function Faq() {
  useEffect(() => {
    new WOW().init();

    $(".tab_content").hide();
    $(".tab_content:first").show();

    $("ul.tabs li").click(function () {
      $(".tab_content").hide();
      var activeTab = $(this).attr("rel");
      $("#" + activeTab).fadeIn();

      $("ul.tabs li").removeClass("active");
      $(this).addClass("active");

      $(".tab_drawer_heading").removeClass("d_active");
      $(".tab_drawer_heading[rel^='" + activeTab + "']").addClass("d_active");
    });

    $(".tab_drawer_heading").click(function () {
      $(".tab_content").hide();
      var d_activeTab = $(this).attr("rel");
      $("#" + d_activeTab).fadeIn();

      $(".tab_drawer_heading").removeClass("d_active");
      $(this).addClass("d_active");

      $("ul.tabs li").removeClass("active");
      $("ul.tabs li[rel^='" + d_activeTab + "']").addClass("active");
    });

    // Initialize the first accordion item
    $(".accordion > li:eq(0) a").addClass("active").next().slideDown();

    $(".accordion a").click(function (j) {
      var dropDown = $(this).closest("li").find("p");

      // Slide up all other content except the one clicked
      $(this).closest(".accordion").find("p").not(dropDown).slideUp();

      // Toggle active class on the clicked link
      if ($(this).hasClass("active")) {
        $(this).removeClass("active");
      } else {
        $(this).closest(".accordion").find("a.active").removeClass("active");
        $(this).addClass("active");
      }

      // Toggle the content of the clicked item
      dropDown.stop(false, true).slideToggle();

      j.preventDefault();
    });
  }, []);

  return (
    <div className="faq-section style-four pt-100 pb-100">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="dreamit-section-title two text-center pb-20">
              <div className="dreamit-section-sub-title">
                <h5>
                  {" "}
                  <img src={ABOUT_ICO} alt="ABOUT_ICO" /> FAQS
                </h5>
              </div>
              <div className="dreamit-section-main-title">
                <h1>Frequently asked Question</h1>
              </div>
              <div className="dreamit-section-content-text">
                <p>
                  Monotonectally productivate virtual benefits vis-a-vis clicks
                  ship. Seamlessly generate user friendly
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row pt-25">
          <div className="col-lg-12">
            <div className="tab-content text-center">
              <ul className="tabs">
                <li className="active" rel="tab1">
                  {" "}
                  General{" "}
                </li>
                <li rel="tab2"> Token </li>
                <li rel="tab3"> Client </li>
                <li rel="tab4"> Pre ICO</li>
                <li rel="tab5"> Legal</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row align-items-center pt-20">
          <div className="col-lg-6 col-md-12">
            <div className="tab_container">
              <h3 className="d_active tab_drawer_heading" rel="tab1">
                Tab 1
              </h3>
              <div id="tab1" className="tab_content">
                <ul className="accordion">
                  <li>
                    <a href="#">What is ICO</a>
                    <p>
                      Holisticly coordinate highest standards in communities
                      rather than cute ures. Distinctively supply highly
                      efficient networks for integrated fors vize adaptive value
                      through enabled
                    </p>
                  </li>
                  <li>
                    <a href="#">How can I participate in the ICO Token sale?</a>
                    <p>
                      Holisticly coordinate highest standards in communities
                      rather than cute ures. Distinctively supply highly
                      efficient networks for integrated fors vize adaptive value
                      through enabled
                    </p>
                  </li>
                  <li>
                    <a href="#">What cryptocurrencies can I use to purchase?</a>
                    <p>
                      Holisticly coordinate highest standards in communities
                      rather than cute ures. Distinctively supply highly
                      efficient networks for integrated fors vize adaptive value
                      through enabled obcaecati magni ullam nobis voluptas
                      fugiat tenetur voluptatum quas tempora maxime
                    </p>
                  </li>
                  <li>
                    <a href="#">What cryptocurrencies can I use to purchase?</a>
                    <p>
                      Holisticly coordinate highest standards in communities
                      rather than cute ures. Distinctively supply highly
                      efficient networks for integrated fors vize adaptive value
                      through enabled{" "}
                    </p>
                  </li>
                </ul>
              </div>
              <h3 className="tab_drawer_heading" rel="tab2">
                {" "}
                Token{" "}
              </h3>
              <div id="tab2" className="tab_content">
                <ul className="accordion">
                  <li>
                    <a href="#">What cryptocurrencies can I use to purchase?</a>
                    <p>
                      Holisticly coordinate highest standards in communities
                      rather than cute ures. Distinctively supply highly
                      efficient networks for integrated fors vize adaptive value
                      through enabled
                    </p>
                  </li>
                  <li>
                    <a href="#">How can I participate in the ICO Token sale?</a>
                    <p>
                      Holisticly coordinate highest standards in communities
                      rather than cute ures. Distinctively supply highly
                      efficient networks for integrated fors vize adaptive value
                      through enabled
                    </p>
                  </li>
                  <li>
                    <a href="#">Dolor sit Amet</a>
                    <p>
                      Holisticly coordinate highest standards in communities
                      rather than cute ures. Distinctively supply highly
                      efficient networks for integrated fors vize adaptive value
                      through enabled{" "}
                    </p>
                  </li>
                  <li>
                    <a href="#">Dolor sit Amet</a>
                    <p>
                      Holisticly coordinate highest standards in communities
                      rather than cute ures. Distinctively supply highly
                      efficient networks for integrated fors vize adaptive value
                      through enabled
                    </p>
                  </li>
                </ul>
              </div>
              <h3 className="tab_drawer_heading" rel="tab3">
                {" "}
                Client{" "}
              </h3>
              <div id="tab3" className="tab_content">
                <ul className="accordion">
                  <li>
                    <a href="#">What is ICO</a>
                    <p>
                      Holisticly coordinate highest standards in communities
                      rather than cute ures. Distinctively supply highly
                      efficient networks for integrated fors vize adaptive value
                      through enabled
                    </p>
                  </li>
                  <li>
                    <a href="#">Repellat Odit Aliquid</a>
                    <p>
                      Holisticly coordinate highest standards in communities
                      rather than cute ures. Distinctively supply highly
                      efficient networks for integrated fors vize adaptive value
                      through enabled{" "}
                    </p>
                  </li>
                  <li>
                    <a href="#">Dolor sit Amet</a>
                    <p>
                      Holisticly coordinate highest standards in communities
                      rather than cute ures. Distinctively supply highly
                      efficient networks for integrated fors vize adaptive value
                      through enabled{" "}
                    </p>
                  </li>
                  <li>
                    <a href="#">Dolor sit Amet</a>
                    <p>
                      Holisticly coordinate highest standards in communities
                      rather than cute ures. Distinctively supply highly
                      efficient networks for integrated fors vize adaptive value
                      through enabled
                    </p>
                  </li>
                </ul>
              </div>
              <h3 className="tab_drawer_heading" rel="tab4">
                {" "}
                Pre ICO{" "}
              </h3>
              <div id="tab4" className="tab_content">
                <ul className="accordion">
                  <li>
                    <a href="#">What is ICO</a>
                    <p>
                      Holisticly coordinate highest standards in communities
                      rather than cute ures. Distinctively supply highly
                      efficient networks for integrated fors vize adaptive value
                      through enabled
                    </p>
                  </li>
                  <li>
                    <a href="#">Repellat Odit Aliquid</a>
                    <p>
                      Holisticly coordinate highest standards in communities
                      rather than cute ures. Distinctively supply highly
                      efficient networks for integrated fors vize adaptive value
                      through enabled
                    </p>
                  </li>
                  <li>
                    <a href="#">Dolor sit Amet</a>
                    <p>
                      Holisticly coordinate highest standards in communities
                      rather than cute ures. Distinctively supply highly
                      efficient networks for integrated fors vize adaptive value
                      through enabled
                    </p>
                  </li>
                  <li>
                    <a href="#">Dolor sit Amet</a>
                    <p>
                      Holisticly coordinate highest standards in communities
                      rather than cute ures. Distinctively supply highly
                      efficient networks for integrated fors vize adaptive value
                      through enabled
                    </p>
                  </li>
                </ul>
              </div>
              <h3 className="tab_drawer_heading" rel="tab5">
                {" "}
                Legal{" "}
              </h3>
              <div id="tab5" className="tab_content">
                <ul className="accordion">
                  <li>
                    <a href="#">What is ICO</a>
                    <p>
                      Holisticly coordinate highest standards in communities
                      rather than cute ures. Distinctively supply highly
                      efficient networks for integrated fors vize adaptive value
                      through enabled
                    </p>
                  </li>
                  <li>
                    <a href="#">Repellat Odit Aliquid</a>
                    <p>
                      Holisticly coordinate highest standards in communities
                      rather than cute ures. Distinctively supply highly
                      efficient networks for integrated fors vize adaptive value
                      through enabled
                    </p>
                  </li>
                  <li>
                    <a href="#">Dolor sit Amet</a>
                    <p>
                      Holisticly coordinate highest standards in communities
                      rather than cute ures. Distinctively supply highly
                      efficient networks for integrated fors vize adaptive value
                      through enabled
                    </p>
                  </li>
                  <li>
                    <a href="#">Dolor sit Amet</a>
                    <p>
                      Holisticly coordinate highest standards in communities
                      rather than cute ures. Distinctively supply highly
                      efficient networks for integrated fors vize adaptive value
                      through enabled
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="faq-thumb">
              <img src="assets/images/faq.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Faq;
