import React from "react";
import NavBar from "../components/navbar";
import Home from "../pages/home";
import StickyNavbar from "../components/stickyNavbar";

function DefaultLayout() {
  return (
    <>
      <StickyNavbar />
      <NavBar />
      <Home />
    </>
  );
}

export default DefaultLayout;
