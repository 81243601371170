import React from "react";
import PLAY_STORE from "../images/new/shape3.png";
import APP_STORE from "../images/new/shape4.png";
import PHONE from "../images/new/phone.png";
import FLOATING_BTC from "../images/new/naest_blue.png";

function CallSection() {
  return (
    <div className="call-section pt-100 pb-120">
      <div className="container">
        <div className="row section">
          <div className="col-lg-6 col-md-6">
            <div className="single-call-content">
              <div className="call-title">
                <h2>
                  Also available on IOS
                  <br /> and Android
                </h2>
              </div>
              <div className="call-content-text">
                <p>
                  Completely whiteboard robust interfaces without multidiscipli
                  has to manufactured products. Progressively myocardinate
                  cooperat crypt technologies through principle-
                </p>
              </div>
              <span>
                <img src={APP_STORE} alt="APP_STORE" />
              </span>
              <span className="more">
                <img src={PLAY_STORE} alt="PLAY_STORE" />
              </span>
            </div>
          </div>
          <div className="col-lg-6 col-md-6">
            <div className="call-shape bounce-animate2">
              <img src={PHONE} alt="PHONE" />
            </div>
            <div className="call-shape2 wooo">
              <img src={FLOATING_BTC} alt="FLOATING_BTC" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CallSection;
