import React, { useEffect, useRef, useState } from "react";
import Slider from "../components/slider";
import PreSale from "../components/preSale";
import Feature from "../components/feature";
import About from "../components/about";
import Transactions from "../components/transactions";
import RoadMap from "../components/roadMap";
import CallSection from "../components/callSection";
import TeamSection from "../components/teamSection";
import Blog from "../components/blog";
import Contact from "../components/contact";
import Footer from "../components/footer";
import Faq from "../components/faq";
import Chart from "../components/chart";

function Home() {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setShowButton(scrollPosition > 200);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll); // Cleanup
  }, []);

  const scrollTo = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <Slider />
      <Feature />
      <Transactions />
      {/* <PreSale /> */}
      <About />
      <CallSection />
      <RoadMap />
      {/* <TeamSection /> */}
      <Chart />
      <Faq />
      {/* <Blog /> */}
      <Contact />
      <Footer />
      {showButton && (
        <div className="scroll-area" onClick={scrollTo}>
          <div className="top-wrap">
            <div className="go-top-btn-wraper">
              <div
                className={`go-top go-top-button active `}
                //   onClick={handleClick}
              >
                <i className="fas fa-arrow-up"></i>
                <i className="fas fa-arrow-up"></i>
              </div>
            </div>
          </div>
          {/* Your other content here */}
        </div>
      )}
    </>
  );
}

export default Home;
