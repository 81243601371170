import React, { useEffect, useState } from "react";
import BITCOIN from "../images/new/bit.png";
import BACK_BITCOIN from "../images/new/back.png";
import ETH from "../images/new/bit2.png";
import BACK_ETH from "../images/new/back2.png";
import NAEST from "../images/new/naest_blue.png";
import BACK_TETHER from "../images/new/back3.png";
import axios from "axios";

function Feature() {
  const [coinsList, setCoinList] = useState([]);

  useEffect(() => {
    getTokensStatistics();
  }, []);

  useEffect(() => {
    console.log("coinsList filtred ====>", coinsList);
  }, [coinsList]);

  const getTokensStatistics = async () => {
    try {
      const result = await axios.get("https://api.coincap.io/v2/assets");
      console.log("result ===>", result.data.data.slice(0, 10));
      const filteredData = result.data.data
        .slice(0, 10)
        .filter((item: any) => item.id === "bitcoin" || item.id === "ethereum");
      setCoinList(filteredData);
    } catch (error) {}
  };

  function formatNumber(number: number) {
    const SI_SYMBOLS = ["", "K", "M", "B", "T", "P", "E"];
    const exponent = Math.floor(Math.log(Math.abs(number)) / Math.log(1000));
    const formattedValue = (number / Math.pow(1000, exponent)).toFixed(1);
    return formattedValue + SI_SYMBOLS[exponent];
  }

  return (
    // <!-- feature section -->
    <div className="feature-section pt-80">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="single-feature-box">
              <div className="feature-thumb">
                <img src={BITCOIN} alt="BITCOIN" />
              </div>
              <div className="feature-content">
                <span>Bitcoin</span>
                {coinsList && coinsList.length !== 0 && (
                  <h3>
                    USD {formatNumber(parseFloat(coinsList[0]["supply"]))}
                  </h3>
                )}
                <p>BTC</p>
              </div>
              <div className="shape">
                <img src={BACK_BITCOIN} alt="BACK_BITCOIN" />
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-feature-box">
              <div className="feature-thumb">
                <img src={ETH} alt="ETH" />
              </div>
              <div className="feature-content">
                <span>Ethereum</span>
                {coinsList && coinsList.length !== 0 && (
                  <h3>
                    USD {formatNumber(parseFloat(coinsList[1]["supply"]))}
                  </h3>
                )}
                <p>ETH</p>
              </div>
              <div className="shape">
                <img src={BACK_ETH} alt="BACK_ETH" />
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-feature-box">
              <div className="feature-thumb">
                <img className="icon-img-price" src={NAEST} alt="NAEST" />
              </div>
              <div className="feature-content">
                <span>Naest</span>
                <h3>USD 299.08</h3>
                <p>THR</p>
              </div>
              <div className="shape">
                <img src={BACK_TETHER} alt="BACK_TETHER" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Feature;
