import React from "react";
import ABOUT_ICO from "../images/naest_logo.png";
import ABOUT from "../images/new/about.png";
import ABOUT_SHAPE from "../images/new/about-shape.png";
import ICON_ONE from "../images/new/icon.png";
import ICON_TWO from "../images/new/icon2.png";
import ICON_THREE from "../images/new/icon3.png";
import ROUND from "../images/new/round.png";

function About() {
  return (
    // <!-- about section -->
    <div className="about-section style-three pt-100 pb-50" id="about-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="dreamit-section-title two text-center pb-20">
              <div className="dreamit-section-sub-title">
                <h5>
                  <img className="icon-img" src={ABOUT_ICO} alt="ABOUT_ICO" />
                  <span>About ICO</span>
                </h5>
              </div>
              <div className="dreamit-section-main-title">
                <h1>About Crypto ICO</h1>
              </div>
              <div className="dreamit-section-content-text">
                <p>
                  Monotonectally productivate virtual benefits vis-a-vis clicks
                  ship. Seamlessly generate user friendly
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row pt-30 align-items-center">
          <div className="col-lg-6 col-md-6">
            <div className="about-box">
              <div className="about-thumb">
                <img src={ABOUT} alt="ABOUT" />
                <div className="about-shape">
                  <img src={ABOUT_SHAPE} alt="ABOUT_SHAPE" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6">
            <div className="about-single-icon-box d-flex">
              <div className="about-icon-thumb">
                <img src={ICON_ONE} alt="ICON_ONE" />
              </div>
              <div className="about-content">
                <h2>Money Exchange</h2>
                <p>
                  Continually pontificate corporate deliverables through
                  clicks-and-mortar intellectual.
                </p>
              </div>
            </div>
            <div className="about-single-icon-box d-flex">
              <div className="about-icon-thumb">
                <img src={ICON_TWO} alt="ICON_TWO" />
              </div>
              <div className="about-content">
                <h2>Balance Transfer</h2>
                <p>
                  Continually pontificate corporate deliverables through
                  clicks-and-mortar intellectual.
                </p>
              </div>
            </div>
            <div className="about-single-icon-box d-flex">
              <div className="about-icon-thumb">
                <img src={ICON_THREE} alt="ICON_THREE" />
              </div>
              <div className="about-content">
                <h2>Balance Transfer</h2>
                <p>
                  Continually pontificate corporate deliverables through
                  clicks-and-mortar intellectual.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="about-shape-one bounce-animate2">
          <img src={ROUND} alt="ROUND" />
        </div>
      </div>
    </div>
  );
}

export default About;
