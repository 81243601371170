import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

import {
  ThirdwebProvider,
  coinbaseWallet,
  metamaskWallet,
  
} from "@thirdweb-dev/react";
import { Avalanche ,AvalancheFuji} from "@thirdweb-dev/chains";
import reportWebVitals from "./reportWebVitals";
import { GlobalContextProvider } from "./context/useGlobalContext";
import "./i18n";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const activeChain = "ether";
root.render(
  <React.StrictMode>
    <GlobalContextProvider>
      <ThirdwebProvider
        activeChain={AvalancheFuji}
        supportedWallets={[metamaskWallet(), coinbaseWallet()]}
        supportedChains={[AvalancheFuji,Avalanche]}
      >
        <App />
      </ThirdwebProvider>
    </GlobalContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
