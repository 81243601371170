import React, { useState } from "react";
import { slide as Menu } from "react-burger-menu";
import { useTranslation } from "react-i18next";
import { Link } from "react-scroll";

function MobileMenu() {
  const { t, i18n } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);

  const handleStateChange = (state: any) => {
    setMenuOpen(state.isOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };
  return (
    <div>
      <Menu
        right
        isOpen={menuOpen}
        onStateChange={(state: any) => handleStateChange(state)}
      >
        <Link to="about-section" smooth={false} duration={500}>
          <a className="menu-item" onClick={closeMenu}>
            {t("about")}
          </a>
        </Link>
        <Link to="road-map-section" smooth={false} duration={500}>
          <a href="road-map.html" className="menu-item">
            {t("road_map")}
          </a>
        </Link>
        <li>
          <a href="contact.html">{t("how_buy")}</a>
        </li>
        <li>
          <a href="contact.html">{t("faq")}</a>
        </li>
      </Menu>
      <main id="page-wrap"></main>
    </div>
  );
}

export default MobileMenu;
