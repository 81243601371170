import React from "react";
import ABOUT_ICO from "../images/naest_logo.png";
import ROAD from "../images/new/road.png";
import ROUND from "../images/new/round.png";

function RoadMap() {
  return (
    <div
      className="road-map style-two upper pt-100 pb-50"
      id="road-map-section"
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="dreamit-section-title two text-center pb-20">
              <div className="dreamit-section-sub-title">
                <h5>
                  {" "}
                  <img
                    className="icon-img"
                    src={ABOUT_ICO}
                    alt="ABOUT_ICO"
                  />{" "}
                  Road Map
                </h5>
              </div>
              <div className="dreamit-section-main-title">
                <h1>Our Strategy & Roadmap</h1>
              </div>
              <div className="dreamit-section-content-text">
                <p>
                  Monotonectally productivate virtual benefits vis-a-vis clicks
                  ship. Seamlessly generate user friendly
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row pt-75">
          <div className="col-lg-1 col-md-1"></div>
          <div className="col-lg-10">
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="single-road-map-box">
                  <div className="single-road-map-content">
                    <h4>February 2021</h4>
                  </div>
                  <div className="road-map-content-inner">
                    <h3>Research</h3>
                    <p>Concept Generation Team member</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="single-road-map-box">
                  <div className="single-road-map-content">
                    <h4>March 2021</h4>
                  </div>
                  <div className="road-map-content-inner">
                    <h3>Design</h3>
                    <p>Concept Generation Team member</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="single-road-map-box">
                  <div className="single-road-map-content">
                    <h4>April 2021</h4>
                  </div>
                  <div className="road-map-content-inner">
                    <h3>Pre-Sale</h3>
                    <p>Concept Generation Team member</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-1 col-md-1"></div>
        </div>
        <div className="row tooper pt-55">
          <div className="col-lg-2"></div>
          <div className="col-lg-8">
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="single-road-map-box two">
                  <div className="single-road-map-content">
                    <h4>May 2021</h4>
                  </div>
                  <div className="road-map-content-inner">
                    <h3>App Beta Test</h3>
                    <p>Concept Generation Team member</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="single-road-map-box two">
                  <div className="single-road-map-content">
                    <h4>July 2021</h4>
                  </div>
                  <div className="road-map-content-inner">
                    <h3>Hardware</h3>
                    <p>Concept Generation Team member</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2"></div>
        </div>
        <div className="row tooper pt-55">
          <div className="col-lg-1 col-md-1"></div>

          <div className="col-lg-10">
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="single-road-map-box">
                  <div className="single-road-map-content">
                    <h4>February 2021</h4>
                  </div>
                  <div className="road-map-content-inner">
                    <h3>Research</h3>
                    <p>Concept Generation Team member</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="single-road-map-box">
                  <div className="single-road-map-content">
                    <h4>March 2021</h4>
                  </div>
                  <div className="road-map-content-inner">
                    <h3>Design</h3>
                    <p>Concept Generation Team member</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="single-road-map-box">
                  <div className="single-road-map-content">
                    <h4>April 2021</h4>
                  </div>
                  <div className="road-map-content-inner">
                    <h3>Pre-Sale</h3>
                    <p>Concept Generation Team member</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-1 col-md-1"></div>
          <div className="shape16">
            <img src={ROAD} alt="ROAD" />
          </div>
        </div>
        <div className="shape17 bounce-animate2">
          <img src={ROUND} alt="ROUND" />
        </div>
      </div>
    </div>
  );
}

export default RoadMap;
