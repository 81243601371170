import React, { useEffect, useState } from "react";
import LOGO from "../images/naest_logo.png";
import { walletFormatter } from "../utils/functions/formats";
import { useGlobalContext } from "../context/useGlobalContext";
import { observer } from "mobx-react";
import { Link } from "react-scroll";
import EN from "../images/flags/uk.png";
import FR from "../images/flags/fr.png";
import { useTranslation } from "react-i18next";
import { ConnectWallet } from "@thirdweb-dev/react";

const NavBar = observer(() => {
  const { userStore } = useGlobalContext();
  const [isSticky, setIsSticky] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { t, i18n } = useTranslation();

  const defaultImages: any = {
    en: EN,
    fr: FR,
  };

  useEffect(() => {
    const handleScroll = () => {
      const scroll = window.scrollY;
      if (scroll < 100) {
        setIsSticky(false);
      } else {
        setIsSticky(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const handleLanguageChange = (lang: string) => {
    setDropdownOpen(false);
    setSelectedLanguage(lang);
    i18n.changeLanguage(lang);
    // You can add logic here to update the language in your app based on the selected value
  };
  return (
    <>
      {/* <!--==================================================-->
  <!--Start Header Section-->
  <!--===================================================--> */}
      <div
        className={isSticky ? "sticky-nav header-area" : "header-area"}
        id="sticky-header"
      >
        <div className="container">
          <div className="row align-items-center d-flex">
            <div className="col-lg-9">
              <nav className="cryptozen_menu">
                <div className="header-menu">
                  <ul className="nav_scroll">
                    <Link to="demo-section" smooth={true} duration={1000}>
                      {/* <li>
                        <a href="#">{t("demo")}</a>
                      </li> */}
                    </Link>
                    <Link to="about-section" smooth={false} duration={500}>
                      <li>
                        <a href="about.html">{t("about")}</a>{" "}
                      </li>
                    </Link>
                    {/* <li>
                      <a href="#">{t("pages")}</a>
                    </li> */}
                    <Link to="road-map-section" smooth={false} duration={500}>
                      <li>
                        <a href="road-map.html">{t("road_map")}</a>
                      </li>
                    </Link>
                    {/* <li>
                      <a href="team.html">{t("team")}</a>
                    </li> */}
                    <li>
                      <a href="contact.html">{t("how_buy")}</a>
                    </li>
                    <li>
                      <a href="contact.html">{t("faq")}</a>
                    </li>
                    {/* <li className={`dropdown ${dropdownOpen ? "open" : ""}`}>
                      <button
                        className="dropdown-select "
                        type="button"
                        onClick={handleDropdownToggle}
                      >
                        <img
                          src={defaultImages[selectedLanguage]}
                          alt={`${defaultImages[selectedLanguage]}`}
                        />{" "}
                        {selectedLanguage.toUpperCase()}
                      </button>
                      <div
                        className={`dropdown-menu dropdown-position ${
                          dropdownOpen ? "show" : ""
                        }`}
                      >
                        <div
                          className="dropdown-item"
                          onClick={() => handleLanguageChange("en")}
                        >
                          <img src={EN} alt="EN" /> EN
                        </div>
                        <div
                          className="dropdown-item"
                          onClick={() => handleLanguageChange("fr")}
                        >
                          <img src={FR} alt="FR" /> FR
                        </div>
                      </div>
                    </li> */}
                  </ul>
            
                </div>
              </nav>
            </div>
            <div className="col-lg-3">
              <div className="footer-about-social-icon right-social-media-block ">
                <ul>
                  <li>
                    <a href="#">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-pinterest"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </li>
                </ul>
              </div>
              {/* <div className="header-logo">
                <a className="main-logo" href="#">
                  <img src={LOGO} className="logo-img" alt="LOGO" />
                  {!isSticky && <h1 className="text-logo">aest</h1>}
                </a>
                <a
                  className="stiky-logo"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "baseline",
                  }}
                  href="index.html"
                >
                  <img src={LOGO} className="logo-img" alt="LOGO" />
                  {isSticky && <h1 className="text-logo">aest</h1>}
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* <!-- mobile menu seection --> */}
      {/* <div className="mobile-menu-area d-sm-block d-md-block d-lg-none ">
        <div className="mobile-menu">
          <nav className="itsoft_menu">
            <ul className="nav_scroll">
              <li>
                <a href="#">demo</a>
                <div className="sub-menu">
                  <ul>
                    <li>
                      <a href="index.html">homepage one</a>
                    </li>
                    <li>
                      <a href="index-2.html">homepage two </a>
                    </li>
                    <li>
                      <a href="index-3.html">homepage three </a>
                    </li>
                    <li>
                      <a href="index-4.html">homepage four</a>
                    </li>
                    <li>
                      <a href="index-9.html">homepage five New</a>
                    </li>
                    <li>
                      <a href="index-6.html">animation page</a>
                    </li>
                    <li>
                      <a href="index-5.html">Landing Page 01</a>
                    </li>
                    <li>
                      <a href="index-7.html">Landing Page 02</a>
                    </li>
                    <li>
                      <a href="index-8.html">Home Video</a>
                    </li>
                    <li>
                      <a href="index-10.html">Home Particle</a>
                    </li>
                    <li>
                      <a href="animation.html">Animation</a>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <a href="about.html">about</a>
              </li>
              <li>
                <a href="#">pages</a>
                <div className="sub-menu">
                  <ul>
                    <li>
                      <a href="about.html">about us </a>
                    </li>
                    <li>
                      <a href="team.html">our team</a>
                    </li>
                    <li>
                      <a href="history.html">History</a>
                    </li>
                    <li>
                      <a href="token.html">token</a>
                    </li>
                    <li>
                      <a href="road-map.html">road</a>
                    </li>
                    <li>
                      <a href="contact.html">contact</a>
                    </li>
                    <li>
                      <a href="choose.html">choose</a>
                    </li>
                    <li>
                      <a href="faq.html">faq</a>
                    </li>
                    <li>
                      <a href="blog-details.html">blog details</a>
                    </li>
                    <li>
                      <a href="blog-grid.html">blog grid</a>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <a href="road-map.html">road map</a>
              </li>
              <li>
                <a href="team.html">team</a>
              </li>
              <li>
                <a href="contact.html">contact</a>
              </li>
            </ul>
          </nav>
        </div>
      </div> */}
      {/* <!--==================================================--> */}
    </>
  );
});

export default NavBar;
