import React, { useEffect, useState } from "react";
import BTC from "../images/new/01.png";
import ETH from "../images/new/2.png";
import USDT from "../images/new/4.png";
import BNB from "../images/new/5.png";
import SOL from "../images/new/4.png";
import DOGE from "../images/new/8.png";
import USDC from "../images/new/usdc.png";
import AVAX from "../images/new/avax.png";
import axios from "axios";

function Transactions() {
  const [coinsList, setCoinList] = useState([]);
  const defaultImages: any = {
    BTC: BTC,
    ETH: ETH,
    USDT: USDT,
    BNB: BNB,
    SOL: SOL,
    DOGE: DOGE,
    USDC: USDC,
    AVAX: AVAX,
  };

  useEffect(() => {
    getTokensStatistics();
  }, []);
  useEffect(() => {
    console.log("coinsList ====>", coinsList);
  }, [coinsList]);

  const getTokensStatistics = async () => {
    try {
      const result = await axios.get("https://api.coincap.io/v2/assets");
      console.log("result ===>", result.data.data.slice(0, 10));
      setCoinList(result.data.data.slice(0, 10));
    } catch (error) {}
  };

  function formatNumber(number: number) {
    const SI_SYMBOLS = ["", "K", "M", "B", "T", "P", "E"];
    const exponent = Math.floor(Math.log(Math.abs(number)) / Math.log(1000));
    const formattedValue = (number / Math.pow(1000, exponent)).toFixed(1);
    return formattedValue + SI_SYMBOLS[exponent];
  }

  return (
    <div className="table-section pt-80 pb-80">
      <div className="container">
        <div className="row">
          <div className="table-reponsive box">
            <div className="table-responsive-content">
              <h2>Current Market</h2>
              <span>+0.36%</span>
              <p>Market up in the last 24 hours</p>
            </div>
            <table id="example" className="table table-striped table-bordered">
              <thead>
                <tr>
                  <th id="all_token">All Tokens</th>
                  <th id="avaiable">Available</th>
                  <th id="gainers">marketCapUsd</th>
                  <th id="losers">vwap24Hr</th>
                  <th id="date">T. Supply</th>
                  <th>24H Volume</th>
                </tr>
              </thead>
              <tbody>
                {coinsList.map(
                  (coin: any, index) =>
                    coin.symbol !== "XRP" &&
                    coin.symbol !== "ADA" && (
                      <tr className="single" key={index}>
                        <td>
                          {" "}
                          <img
                            src={
                              defaultImages[coin.symbol] || "placeholder.png"
                            }
                            alt={defaultImages[coin.symbol]}
                          />{" "}
                          {coin.name}
                        </td>
                        <td>${parseFloat(coin.priceUsd).toFixed(10)}</td>
                        <td>
                          {/* <img src={UP_RATE} alt="UP_RATE" /> 5.43% */}$
                          {formatNumber(parseFloat(coin.marketCapUsd))}
                        </td>
                        <td>{parseFloat(coin.vwap24Hr).toFixed(6)}</td>
                        <td>{formatNumber(parseFloat(coin.supply))}</td>
                        <td>{parseFloat(coin.volumeUsd24Hr).toFixed(6)}</td>
                      </tr>
                    )
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Transactions;
