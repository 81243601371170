import React from "react";

import DefaultLayout from "./layout/defaultLayout";

function App() {
  return (
    <div>
      <DefaultLayout />
    </div>
  );
}

export default App;
