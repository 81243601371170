export function walletFormatter(walletAddress: string) {
  // Extract the first 3 characters
  const firstPart = walletAddress.substring(0, 4);

  // Extract the last 3 characters
  const lastPart = walletAddress.slice(-5);

  // Combine the parts with a separator (optional)
  const formattedAddress = `${firstPart}.....${lastPart}`;
  return formattedAddress;
}

export function getValueNeast(token: string) {
  switch (token) {
    case "AVAX":
      return 0.001;
    case "USDC":
      return 0.25;
    case "USDT":
      return 0.25;
    default:
      return 0;
  }
}
