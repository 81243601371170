import { makeAutoObservable } from "mobx";

class UserStore {
  hasProvider: boolean = false;
  initialState = { accounts: [] };
  wallet: any = this.initialState;

  constructor() {
    makeAutoObservable(this);
  }

  setHasProvider(value: boolean) {
    this.hasProvider = value;
  }
  setWallet(value: any) {
    this.wallet = value;
  }
}

export default new UserStore();
