import React, { useEffect, useState } from "react";
import LOGO from "../images/naest_logo.png";
import "../css/stickyNavbar.css";
import MobileMenu from "./mobileMenu";
import useScreenSize from "../hooks/useScreenSize";
import { useGlobalContext } from "../context/useGlobalContext";
import { observer } from "mobx-react";
import { ConnectWallet } from "@thirdweb-dev/react";

const StickyNavbar = observer(() => {
  const [isSticky, setIsSticky] = useState(false);
  const { userStore } = useGlobalContext();
  const isScreenSize = useScreenSize(992);

  useEffect(() => {
    const handleScroll = () => {
      const scroll = window.scrollY;
      if (scroll < 100) {
        setIsSticky(false);
      } else {
        setIsSticky(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav className={isSticky ? "sticky-navbar navbar" : "navbar"}>
      <div className="navbar-logo">
        <a className="main-logo" href="#">
          <img src={LOGO} className="logo-img" alt="LOGO" />
          <h1 className="text-logo">aest</h1>
        </a>
      </div>
      <div className="navbar-info">
        <span className="price">
          PRIX DE RÉFÉRENCE $0.01 <span className="percentage">(+205.81%)</span>
        </span>
        <span className="price">
          PROCHAIN PRIX $0.00376 <span className="percentage">(+14.98%)</span>
        </span>
        <span className="price-now">PRICE NOW $0.00327</span>
        {/* <button className="buy-token">Acheter Le Jeton</button>
         */}
        {userStore.wallet.accounts.length === 0 && (
          <div className="buy-btn wow fadeInUp " data-wow-delay=".9s">
            <a href="#"> Acheter Le Jeton</a>
          </div>
        )}
        {userStore.wallet.accounts.length > 0 && (
          // <div className="header-btn">
          //   <a href="#">
          //     {walletFormatter(userStore.wallet.accounts[0])}
          //   </a>
          // </div>
          <ConnectWallet className="header-btn" />
        )}
      </div>
      {!isScreenSize && <MobileMenu />}
    </nav>
  );
});

export default StickyNavbar;
