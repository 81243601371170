import detectEthereumProvider from "@metamask/detect-provider";
import { observer } from "mobx-react";
import {
  ConnectWallet,
  useAddress,
  useWallet,
  getProviderFromRpcUrl,
  ThirdwebConnectedWalletProvider,
} from "@thirdweb-dev/react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { useEffect, useState } from "react";
import Web3 from "web3";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import { useGlobalContext } from "../context/useGlobalContext";
import ABI from "../contracts/NAEST.json";
import NEAST_ADDRESS from "../contracts/address";
import ERC20_ABI from "../contracts/usdc/USDC.json";
import USDT_ADDRESS from "../contracts/usdc/address";
import AVAX from "../images/coin/avax.png";
import BNB_COIN from "../images/coin/bnb.png";
import CARD from "../images/coin/card.svg";
import COINBASE_WALLET from "../images/coin/coinbase-wallet.png";
import METAMASK_COIN from "../images/coin/metamask.png";
import SWAP_ICO from "../images/coin/swap-ico.png";
import USDC from "../images/coin/usdc.png";
import USDT from "../images/coin/usdt.png";
import { getValueNeast } from "../utils/functions/formats";
import CountdownTimer from "./countdownTimer";
import ProgressBar from "@ramonak/react-progress-bar";
import "../css/progressBar.css";
import useScreenSize from "../hooks/useScreenSize";

const APP_NAME = "My Awesome App";
const APP_LOGO_URL = "https://example.com/logo.png";
const RPC_URL = "https://mainnet.infura.io/v3/YOUR_INFURA_KEY"; //
// `https://mainnet.infura.io/v3/${process.env.INFURA_KEY}`
const defaultImages: any = {
  AVAX: AVAX,
  USDT: USDT,
  USDC: USDC,
};

const transactionFeePercentage =
  process.env.REACT_APP_TRANSACTION_FEE_PERCENTAGE ?? "0";
const burnFeePercentage = process.env.REACT_APP_BURN_FEE_PERCENTAGE ?? "0";
const rewardFeePercentage = process.env.REACT_APP_REWARD_FEE_PERCENTAGE ?? "0";
const marketingFeePercentage =
  process.env.REACT_APP_MARKETING_FEE_PERCENTAGE ?? "0";
const liquidityFeePercentage =
  process.env.REACT_APP_LIQUIDITY_FEE_PERCENTAGE ?? "0";
const developmentFeePercentage =
  process.env.REACT_APP_DEVELOPMENT_FEE_PERCENTAGE ?? "0";
let coinbaseWallet: any;

const THREE_DAYS_IN_MS = 3 * 24 * 60 * 60 * 1000;
const NOW_IN_MS = new Date().getTime();

const dateTimeAfterThreeDays = NOW_IN_MS + THREE_DAYS_IN_MS;

const timerProps = {
  isPlaying: true,
  size: 120,
  strokeWidth: 6,
};

const minuteSeconds = 60;
const hourSeconds = 3600;
const daySeconds = 86400;

const getTimeSeconds = (time: number) => (minuteSeconds - time) | 0;
const getTimeMinutes = (time: number) =>
  ((time % hourSeconds) / minuteSeconds) | 0;
const getTimeHours = (time: number) => ((time % daySeconds) / hourSeconds) | 0;
const getTimeDays = (time: number) => (time / daySeconds) | 0;

const stratTime = Date.now() / 1000; // use UNIX timestamp in seconds
const endTime = stratTime + 243248; // use UNIX timestamp in seconds
const remainingTime = endTime - stratTime;
const days = Math.ceil(remainingTime / daySeconds);
const daysDuration = days * daySeconds;

const renderTime = (dimension: string, time: number) => {
  return (
    <div className="time-wrapper">
      <div className="time">{time}</div>
      <div>{dimension}</div>
    </div>
  );
};

const Slider = observer(() => {
  const web3 = new Web3(window.ethereum);
  const { userStore } = useGlobalContext();
  const isScreenSize = useScreenSize(992);
  const walletAddress = useAddress();

  const [swapFromValue, setSwapFromValue] = useState(0);
  const [swapToValue, setSwapToValue] = useState(0);
  const [sumOfPecentage, setSumOfPecentage] = useState(0);
  const [valueTokenRecieved, setValueTokenRecieved] = useState(0);
  const walletInstance = useWallet();
  const [selectedToken, setSelectedToken] = useState("AVAX");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [balance, setBalance] = useState<any>("0");
  const [balanceNaest, setBalanceNaest] = useState<any>("0");
  console.log(walletInstance);
  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const APP_NAME = "My Awesome App";
  const APP_LOGO_URL = "https://example.com/logo.png";

  const handleTokenChange = (token: string) => {
    setSelectedToken(token);
    setDropdownOpen(false);
    if (token === "USDC") {
      fetchUsdcBalance();
    }
  };

  useEffect(() => {
    if (walletAddress) {
      updateWallet([walletAddress]);
    } else {
      updateWallet([]);
    }
  }, [walletAddress]);

  // Avax balance
  const fetchAvaxBalance = async () => {
    try {
      if (walletAddress) {
        const balanceInWei = await web3.eth.getBalance(walletAddress);
        const balanceInEth = web3.utils.fromWei(balanceInWei, "ether");
        setBalance(balanceInEth);
      }
    } catch (error) {
      console.error("Error fetching balance:", error);
    }
  };

  const fetchUsdcBalance = async () => {
    try {
      const contract = new web3.eth.Contract(ERC20_ABI, USDT_ADDRESS);
      const balanceUSDC: any = await contract.methods
        .balanceOf(walletAddress)
        .call();
      setBalance(parseInt(balanceUSDC) / 10 ** 6);
    } catch (error) {}
  };

  const fetchNaestBalance = async () => {
    try {
      const contract = new web3.eth.Contract(ABI.abi, NEAST_ADDRESS);
      const balanceNaset: any = await contract.methods
        .balanceOf(walletAddress)
        .call();
      setBalanceNaest(parseInt(balanceNaset) / 10 ** 18);
    } catch (error) {}
  };
  // get balnace
  useEffect(() => {
    if (selectedToken === "AVAX") {
      fetchAvaxBalance();
    }
    fetchNaestBalance();
  }, [walletAddress, web3.eth]);

  const updateWallet = async (accounts: any) => {
    userStore.setWallet({ accounts });
  };
  const initializeWeb3 = async (walletInstance: any) => {
    let provider;

    if (walletInstance?.walletId === "metamask") {
      provider = window.ethereum;
      await window.ethereum.enable();
    } else if (walletInstance?.walletId === "coinbase") {
      coinbaseWallet = new CoinbaseWalletSDK({
        appName: APP_NAME,
        appLogoUrl: APP_LOGO_URL,
      });

      provider = coinbaseWallet.makeWeb3Provider();
    }

    if (!provider) {
      throw new Error("No provider found");
    }

    return new Web3(provider);
  };

  const buyNEASTToken = async () => {
    if (balance < swapFromValue) {
      alert("Not enough balance !");
      return;
    }
    try {
      if (!window.ethereum) {
        alert("Please install MetaMask to use this feature.");
        return;
      }
      const web3 = await initializeWeb3(walletInstance);
      const accounts = await web3.eth.getAccounts();
      const contract = new web3.eth.Contract(ABI.abi, NEAST_ADDRESS);

      // Manually set fees if getFeeData is not available
      const currentGasPrice = await web3.eth.getGasPrice(); // Legacy gas price
      const maxPriorityFeePerGas = web3.utils.toWei("2", "gwei");
      const maxFeePerGas =
        parseInt(currentGasPrice.toString()) + parseInt(maxPriorityFeePerGas); // Simple sum for illustration

      if (selectedToken === "AVAX") {
        const amountAvax = web3.utils.toWei(swapFromValue.toString(), "ether");
        const estimatedGas = await contract.methods
          .buyTokensWithAVAX()
          .estimateGas({
            from: accounts[0],
            value: amountAvax,
          });
        await contract.methods.buyTokensWithAVAX().send({
          from: accounts[0],
          value: amountAvax,
          gas: estimatedGas.toString(),
          maxPriorityFeePerGas: maxPriorityFeePerGas,
          maxFeePerGas: maxFeePerGas.toString(),
        });
      } else if (selectedToken === "USDT") {
        const tokenContract = new web3.eth.Contract(ERC20_ABI, USDT_ADDRESS);
        const usdtAmount = web3.utils.toWei(swapFromValue.toString(), "mwei");
        const allowance = BigInt(
          await tokenContract.methods
            .allowance(accounts[0], contract.options.address)
            .call()
        );

        if (BigInt(allowance) < BigInt(usdtAmount)) {
          await tokenContract.methods
            .approve(contract.options.address, usdtAmount.toString())
            .send({
              from: accounts[0],
              maxPriorityFeePerGas: maxPriorityFeePerGas,
              maxFeePerGas: maxFeePerGas.toString(),
            });
        }

        const estimatedGas = await contract.methods
          .buyTokensWithERC20(usdtAmount.toString(), USDT_ADDRESS)
          .estimateGas({
            from: accounts[0],
          });
        await contract.methods
          .buyTokensWithERC20(usdtAmount.toString(), USDT_ADDRESS)
          .send({
            from: accounts[0],
            gas: estimatedGas.toString(),
            maxPriorityFeePerGas: maxPriorityFeePerGas,
            maxFeePerGas: maxFeePerGas.toString(),
          });
      }

      setSwapToValue(0);
      setSwapFromValue(0);
      if (walletInstance?.walletId === "coinbase") {
        coinbaseWallet.disconnect();
      }

      console.log("Transaction successful!");
    } catch (error: any) {
      console.error("Error sending transaction:", error);
      alert("Transaction failed: " + error.message);
      if (walletInstance?.walletId === "coinbase") {
        coinbaseWallet.disconnect();
      }
    }
  };

  const caluclteAmount = () => {
    let tokenValue = swapFromValue / getValueNeast(selectedToken);
    setSwapToValue(tokenValue);

    let sumOfPercentages =
      parseFloat(transactionFeePercentage) +
      parseFloat(burnFeePercentage) +
      parseFloat(rewardFeePercentage) +
      parseFloat(marketingFeePercentage) +
      parseFloat(liquidityFeePercentage) +
      parseFloat(developmentFeePercentage);

    setSumOfPecentage(sumOfPercentages);
    setValueTokenRecieved(tokenValue - (tokenValue * sumOfPercentages) / 100);
  };

  useEffect(() => {
    caluclteAmount();
  }, [swapFromValue, swapToValue, selectedToken]);

  return (
    <div className="slider-area style-five d-flex align-items-center">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-6">
            <div className="slider-content">
              <h4 className="wow fadeInUp" data-wow-delay=".2s">
                {" "}
                <span className="other">$</span>Secure & Safe Crypto Currency
              </h4>
              <h1 className="wow fadeInUp" data-wow-delay=".4s">
                Make your <span>Crypto</span> Transaction
              </h1>
              <p className="wow fadeInUp" data-wow-delay=".6s">
                Buy and Sales 100+ Cryptocurrencies with 20+ flat currencies
                market using bank transfers or your credit/debit card in your
                exchange type bitcoin establshed token area.{" "}
              </p>
              <div className="slider-button wow fadeInUp" data-wow-delay=".8s">
                <a href="#">
                  {" "}
                  <i className="fa fa-play"> </i>How it Works
                </a>
              </div>
              <div className="slider-btn wow fadeInUp" data-wow-delay=".9s">
                <a href="#">
                  {" "}
                  <i className="fa fa-file"> </i> White Papers
                </a>
              </div>
            </div>
          </div>
          {/* Start Swap Block */}
          <div className="col-lg-6 col-md-6 mt-5 swap-col">
            <div className="container swap-container">
              {/* <div className="row"> */}
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="trade-main">
                  <div className="swap-area">
                    <div className="row">
                      <div className="col-xl-12">
                        <div className="swap-tool">
                          <div
                            className="swap-header"
                            style={{ textAlign: "center" }}
                          >
                            <h3>Swap</h3>
                            <p>Trade tokens in an instant</p>
                          </div>
                          <div className="swap-body">
                            {/* <CountdownTimer
                              targetDate={dateTimeAfterThreeDays}
                            /> */}
                            <div className="count-down">
                              <CountdownCircleTimer
                                {...timerProps}
                                colors={[
                                  "#007bff",
                                  "#F7B801",
                                  "#A30000",
                                  "#A30000",
                                ]}
                                colorsTime={[30, 5, 2, 0]}
                                duration={daysDuration}
                                initialRemainingTime={remainingTime}
                                size={!isScreenSize ? 70 : 100}
                              >
                                {({ elapsedTime, color }) => (
                                  <span style={{ color }}>
                                    {renderTime(
                                      "days",
                                      getTimeDays(daysDuration - elapsedTime)
                                    )}
                                  </span>
                                )}
                              </CountdownCircleTimer>
                              <CountdownCircleTimer
                                {...timerProps}
                                colors={[
                                  "#007bff",
                                  "#F7B801",
                                  "#A30000",
                                  "#A30000",
                                ]}
                                colorsTime={[30, 5, 2, 0]}
                                duration={daySeconds}
                                initialRemainingTime={
                                  remainingTime % daySeconds
                                }
                                size={!isScreenSize ? 70 : 100}
                                onComplete={(totalElapsedTime) => ({
                                  shouldRepeat:
                                    remainingTime - totalElapsedTime >
                                    hourSeconds,
                                })}
                              >
                                {({ elapsedTime, color }) => (
                                  <span style={{ color }}>
                                    {renderTime(
                                      "hours",
                                      getTimeHours(daySeconds - elapsedTime)
                                    )}
                                  </span>
                                )}
                              </CountdownCircleTimer>
                              <CountdownCircleTimer
                                {...timerProps}
                                colors={[
                                  "#007bff",
                                  "#F7B801",
                                  "#A30000",
                                  "#A30000",
                                ]}
                                colorsTime={[30, 5, 2, 0]}
                                duration={hourSeconds}
                                initialRemainingTime={
                                  remainingTime % hourSeconds
                                }
                                size={!isScreenSize ? 70 : 100}
                                onComplete={(totalElapsedTime) => ({
                                  shouldRepeat:
                                    remainingTime - totalElapsedTime >
                                    minuteSeconds,
                                })}
                              >
                                {({ elapsedTime, color }) => (
                                  <span style={{ color }}>
                                    {renderTime(
                                      "minutes",
                                      getTimeMinutes(hourSeconds - elapsedTime)
                                    )}
                                  </span>
                                )}
                              </CountdownCircleTimer>
                              <CountdownCircleTimer
                                {...timerProps}
                                duration={minuteSeconds}
                                colors={[
                                  "#007bff",
                                  "#F7B801",
                                  "#A30000",
                                  "#A30000",
                                ]}
                                colorsTime={[30, 5, 2, 0]}
                                initialRemainingTime={
                                  remainingTime % minuteSeconds
                                }
                                size={!isScreenSize ? 70 : 100}
                                onComplete={(totalElapsedTime) => ({
                                  shouldRepeat:
                                    remainingTime - totalElapsedTime > 0,
                                })}
                              >
                                {({ elapsedTime, color }) => (
                                  <span style={{ color }}>
                                    {renderTime(
                                      "seconds",
                                      getTimeSeconds(elapsedTime)
                                    )}
                                  </span>
                                )}
                              </CountdownCircleTimer>
                            </div>
                            <h4 className="fundraiser">
                              USDT Levés : $2,679,117.87 / $3,951,191
                            </h4>
                            <ProgressBar
                              completed={80}
                              className="wrapper-progress-bar"
                              barContainerClassName="container-progress-bar"
                              completedClassName="barCompleted"
                              customLabel="Jusqu'a à l'augmentation des prix"
                              labelClassName="label-progress-bar"
                            />
                            <h6 className="h6-line">
                              <span>1 Naest Coin = $0.000103</span>
                            </h6>
                            <div>
                              <div className="used-coins-block">
                                <h5>Utilisez </h5>
                                <div
                                  className="coin-btn wow fadeInUp"
                                  data-wow-delay=".9s"
                                >
                                  <a
                                    href="#"
                                    onClick={() => handleTokenChange("AVAX")}
                                  >
                                    <img src={AVAX} alt="AVAX" /> AVAX
                                  </a>
                                </div>
                                <h5>ou</h5>
                                <div
                                  className="coin-btn wow fadeInUp"
                                  data-wow-delay=".9s"
                                >
                                  <a
                                    href="#"
                                    onClick={() => handleTokenChange("USDC")}
                                  >
                                    <img src={USDC} alt="USDC" /> USDC
                                  </a>
                                </div>
                                <h5>ou</h5>
                                <div
                                  className="coin-btn wow fadeInUp"
                                  data-wow-delay=".9s"
                                >
                                  <a
                                    href="#"
                                    onClick={() => handleTokenChange("USDT")}
                                  >
                                    <img src={USDT} alt="USDT" />
                                    USDT
                                  </a>
                                </div>
                                <h5>ou</h5>
                                <div
                                  className="coin-btn wow fadeInUp"
                                  data-wow-delay=".9s"
                                >
                                  <a href="#">
                                    <img src={CARD} alt="CARD" /> CARD
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="swap-form">
                              <div className="swap-input-box">
                                <div className="label-block">
                                  <label>Swap From</label>
                                  <span className="swap-balance">
                                    Balance :
                                    {selectedToken === "AVAX"
                                      ? parseFloat(balance).toFixed(4)
                                      : parseFloat(balance).toFixed(2)}
                                  </span>
                                </div>
                                <div className="swap-input-main">
                                  <div className="swap-box">
                                    <div
                                      className={`dropdown ${
                                        dropdownOpen ? "open" : ""
                                      }`}
                                    >
                                      <input
                                        className="swap-input"
                                        type="text"
                                        name="balance"
                                        value={swapFromValue}
                                        onChange={(event: any) =>
                                          setSwapFromValue(event.target.value)
                                        }
                                      />
                                      <div className="menu-item-block">
                                        <button
                                          className="dropdown-select"
                                          type="button"
                                          onClick={handleDropdownToggle}
                                        >
                                          <img
                                            src={defaultImages[selectedToken]}
                                            alt={`${defaultImages[selectedToken]}`}
                                          />{" "}
                                          {selectedToken}
                                        </button>
                                        <div
                                          className={`dropdown-menu ${
                                            dropdownOpen ? "show" : ""
                                          }`}
                                        >
                                          <div
                                            className="dropdown-item"
                                            onClick={() =>
                                              handleTokenChange("AVAX")
                                            }
                                          >
                                            <img src={AVAX} alt="AVAX" /> AVAX
                                          </div>
                                          <div
                                            className="dropdown-item"
                                            onClick={() =>
                                              handleTokenChange("USDC")
                                            }
                                          >
                                            <img src={USDC} alt="USDC_COIN" />{" "}
                                            USDC
                                          </div>
                                          <div
                                            className="dropdown-item"
                                            onClick={() =>
                                              handleTokenChange("USDT")
                                            }
                                          >
                                            <img src={USDT} alt="USDT_COIN" />{" "}
                                            USDT
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="swap-icon">
                                <img src={SWAP_ICO} alt={SWAP_ICO} />
                              </div>
                              <div className="swap-input-box">
                                <div className="label-block">
                                  <label>Swap To</label>
                                  <span className="swap-balance">
                                    Balance : {balanceNaest}
                                  </span>
                                </div>
                                <div className="swap-input-main">
                                  <div className="swap-box">
                                    <div className="dropdown">
                                      <input
                                        className="swap-input"
                                        type="text"
                                        disabled
                                        name="balance"
                                        value={swapToValue}
                                        onChange={(event: any) =>
                                          setSwapToValue(event.target.value)
                                        }
                                      />
                                      <button
                                        className="dropdown-select-no-cheveron"
                                        type="button"
                                        data-target="#select-token-popup"
                                      >
                                        <img src={BNB_COIN} alt={BNB_COIN} />{" "}
                                        Naest{" "}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div>
                                {swapToValue !== 0 && (
                                  <p className="mt-4">
                                    You will receive {valueTokenRecieved}{" "}
                                    tokens, and we'll cover {sumOfPecentage}% of
                                    the gas fees, distributed as follows:
                                    <ul className="swap-btn ml-5">
                                      <li>
                                        {transactionFeePercentage}% for
                                        transaction fees
                                      </li>
                                      <li>
                                        {burnFeePercentage}% for burning tokens
                                      </li>
                                      <li>
                                        {rewardFeePercentage}% for rewarding
                                        users
                                      </li>
                                      <li>
                                        {marketingFeePercentage}% for marketing
                                        purposes
                                      </li>
                                      <li>
                                        {liquidityFeePercentage}% for liquidity
                                        support
                                      </li>
                                      <li>
                                        {developmentFeePercentage}% for
                                        development expenses
                                      </li>
                                    </ul>
                                  </p>
                                )}
                              </div>
                              <div className="swap-btn text-center mt-4">
                                {userStore.wallet.accounts.length > 0 ? (
                                  <button
                                    className="theme-btn"
                                    onClick={() => buyNEASTToken()}
                                  >
                                    Buy Naest
                                  </button>
                                ) : (
                                  <ConnectWallet className="theme-btn" />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* </div> */}
            </div>
          </div>
          {/* End Swap Block */}
        </div>
      </div>
    </div>
  );
});

export default Slider;
