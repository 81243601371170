import { useState, useEffect, useLayoutEffect } from "react";

const useScreenSize = (minWidth: number) => {
  const [isScreenSize, setIsScreenSize] = useState(false);

  useLayoutEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width >= minWidth) {
        setIsScreenSize(true);
      } else {
        setIsScreenSize(false);
      }
    };

    handleResize(); // Check the size initially
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  return isScreenSize;
};

export default useScreenSize;
